import React from 'react';

export default function SuccessStories() {
  const caseStudies = [
    {
      title: "Resource Allocation",
      category: "Learn how we assemble a tailored team, selecting the most suitable candidates for each role, under a fully outsourced collaboration model. ",
    
    },
    {
      title: "Scoping",
      category: " Our rigorous approach to gathering comprehensive requirements, defining accurate project scope, and preventing scope creep while safeguarding critical deliverables.",
   
    },
    {
      title: "Cost Estimation",
      category: "Understand the factors and principles guiding our precise estimates, review sample cost calculations, and discover our cost optimization strategies",
     
    },
    {
      title: "Change Management",
      category: "Our structured process for documenting, assessing, triaging, and implementing feasible change requests in a controlled and transparent manner.",
      
    },
    {
      title: "Success Metrics",
      category: "The Key Performance Indicators (KPIs) we use to objectively evaluate cooperation health and our joint success assessment practices.",
     
    },
    {
      title: "Collaboration and Communication",
      category: "The communication forms and tools we utilize to ensure productive teamwork and seamless interactions with our clients.",
     
    }
  ];

  return (
    <div className="bg-white py-12 lg:px-12">
      <div className="container mx-auto px-4 lg:px-8">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-3 text-center">
  Ensure the Triumph of Your IT Projects:
</h2>
<h6 className="text-lg md:text-xl lg:text-xl font-bold mb-6 text-center">
  Expert Guidance for Strategic Technology Implementations
</h6>
        <div className="w-20 h-1 bg-orange-500 mb-8 mx-auto"></div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {caseStudies.map((study, index) => (
            <div key={index} className="group relative overflow-hidden rounded-lg shadow-lg">
              {/* <img
                src={study.image}
                alt={study.title}
                className="w-full h-56 object-cover transition-transform duration-500 group-hover:scale-110"
              /> */}
               <div className="  bg-white flex flex-col p-6 justify-center items-center">
                <h3 className="text-blue-900 text-2xl font-semibold mb-2">{study.title}</h3>
                <p className=" font-medium mb-4">{study.category}</p>
              </div>
              <div className="absolute inset-0 bg-blue-900  flex flex-col justify-center items-center p-6 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ">
                <h3 className="text-white text-2xl font-semibold mb-2">{study.title}</h3>
                <p className="text-white font-medium mb-4">{study.category}</p>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="text-center mt-12">
          <button className="bg-blue-600 text-white hover:bg-blue-700 px-6 py-3 rounded-full shadow-md transition-colors duration-300">
            Learn More
          </button>
        </div> */}
      </div>
    </div>
  );
}

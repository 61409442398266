
import React, { useState, useEffect } from 'react';

const Navbar = () => {
  const [navbarScrolled, setNavbarScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarScrolled(true);
      } else {
        setNavbarScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
        navbarScrolled ? 'bg-white shadow-sm text-gray-700' : 'bg-transparent text-white'
      } border-b`}
    >
      {/* Main Navbar */}
      <div className="container mx-auto flex items-center justify-between py-3 px-4">
        {/* Logo */}
        <div className="flex items-center space-x-2 ml-6 ">
         <h1 className='text-2xl'>ZSORK</h1>
        </div>

        {/* Navigation Links for Desktop */}
        <nav className="hidden md:flex space-x-6 m-2">
          {['About', 'Services', 'Solutions', 'Industries', 'Technologies', 'Portfolio', 'Pricing'].map((item) => (
            <div className="relative group" key={item}>
              <button
                className={`font-medium focus:outline-none transition-colors duration-300 ${
                  navbarScrolled ? 'text-gray-700 hover:text-blue-800' : 'text-white hover:text-blue-300'
                }`}
              >
                {item}
                <span
                  className={`absolute left-0 right-0 bottom-0 h-0.5 bg-transparent group-hover:${
                    navbarScrolled ? 'bg-blue-800' : 'bg-blue-300'
                  } transition-all duration-300`}
                ></span>
              </button>
            </div>
          ))}
        </nav>

        {/* Contact Us Button for Desktop */}
        <div className="hidden md:block">
          <a
            href="#"
            className={`px-4 py-2 font-semibold rounded focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-300 ${
              navbarScrolled
                ? 'bg-yellow-400 text-black hover:bg-yellow-500 focus:ring-yellow-500'
                : 'bg-white text-black hover:bg-gray-200 focus:ring-white'
            }`}
          >
            Contact us
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button
            onClick={toggleMobileMenu}
            className={`focus:outline-none transition-colors duration-300 ${
              navbarScrolled ? 'text-gray-700 hover:text-blue-800' : 'text-white hover:text-blue-300'
            }`}
          >
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`md:hidden px-4 pb-3 transition-all duration-300 ${
          mobileMenuOpen ? 'block' : 'hidden'
        }`}
      >
        <nav className="flex flex-col space-y-2">
          {['About', 'Services', 'Solutions', 'Industries', 'Technologies', 'Portfolio', 'Pricing'].map((item) => (
            <button
              key={item}
              className={`font-medium focus:outline-none text-left transition-colors duration-300 ${
                navbarScrolled ? 'text-gray-700 hover:text-blue-800' : 'text-white hover:text-blue-300'
              }`}
            >
              {item}
            </button>
          ))}
        </nav>
        <div className="mt-3">
          <a
            href="#"
            className={`px-4 py-2 font-semibold rounded focus:outline-none focus:ring-2 focus:ring-offset-2 block text-center transition-colors duration-300 ${
              navbarScrolled
                ? 'bg-yellow-400 text-black hover:bg-yellow-500 focus:ring-yellow-500'
                : 'bg-white text-black hover:bg-gray-200 focus:ring-white'
            }`}
          >
            Contact us
          </a>
        </div>
      </div>
    </header>
  );
};

export default Navbar;


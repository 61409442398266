// TextImageSection.jsx
import React from 'react';
import It from '../assets/ItImage.png'

const TextImageSection = () => {
  return (
    <section className="flex justify-between items-center mx-5 md:mx-10 lg:mx-20 py-6 shadow-xl" style={{backgroundColor:'#CCDDF7'}}>
      <div className="flex-1 pr-5">
      <div className="text-left m-5">
        <h3 className="text-2xl font-bold mb-2 ms-7">Streamlining Your IT Operations, End-to-End</h3>
       
        <p className="text-base mb-4 text-gray-700  ms-7">
            
        All-encompassing care of your cloud or on-premises infrastructure and applications:
        </p>
        <div className="w-16 h-1 bg-orange-500 mb-4 ms-7 "></div>
        <ul className="list-disc ml-5 text-gray-600 text-lg">
          <li className=" inline-block py-1  max-w-lg text-white bg-blue-900 rounded-lg px-4 py-2 transition-colors duration-300 m-2"> Consulting</li>
          <li className=" inline-block py-1 max-w-lg text-white bg-blue-900 rounded-lg px-4 py-2 transition-colors duration-300 m-2 ">Implementation</li>
          <li className=" inline-block py-1 max-w-lg text-white bg-blue-900 rounded-lg px-4 py-2 transition-colors duration-300 m-2">Management and support</li>
          <li className=" inline-block py-1 max-w-lg text-white bg-blue-900 rounded-lg px-4 py-2 transition-colors duration-300 m-2 ">Security</li>
          <li className=" inline-block py-1 max-w-lg text-white bg-blue-900 rounded-lg px-4 py-2 transition-colors duration-300 m-2">Help desk services</li>
          <li className=" inline-block py-1 max-w-lg text-white bg-blue-900 rounded-lg px-4 py-2 transition-colors duration-300 m-2">Migration</li>
        </ul>
      </div>
      </div>
      <div className="flex-1">
        <img 
          src={It} 
          alt="Description of image" 
          className="w-full h-auto object-cover"
        />
      </div>
    </section>
  );
}

export default TextImageSection;

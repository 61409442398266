// import React from 'react';
import { HeartIcon, BanknotesIcon,ChartBarIcon, ShieldCheckIcon, ShoppingCartIcon, CogIcon, PhoneIcon, TruckIcon, BriefcaseIcon, AcademicCapIcon,BuildingLibraryIcon,ArrowPathIcon,UserGroupIcon, MegaphoneIcon, WrenchScrewdriverIcon, HomeIcon } from '@heroicons/react/24/solid';


const IndustryExpertise = () => {
    const industries = [
        { name: 'Healthcare', icon: HeartIcon },
        { name: 'Banking', icon: BuildingLibraryIcon },
        { name: 'Lending', icon: BanknotesIcon },
        { name: 'Investment', icon: ChartBarIcon },
        { name: 'Insurance', icon: ShieldCheckIcon },
        { name: 'Retail', icon: ShoppingCartIcon },
        { name: 'Manufacturing', icon: CogIcon },
        { name: 'Renewables', icon: ArrowPathIcon },
        { name: 'Telecommunication', icon: PhoneIcon },
        { name: 'Marketing and Advertising', icon: MegaphoneIcon },
        { name: 'Public Services', icon: UserGroupIcon },
        { name: 'Logistics and Transportation', icon: TruckIcon },
        { name: 'Professional Services', icon: BriefcaseIcon },
        { name: 'Automotive', icon: WrenchScrewdriverIcon },
        { name: 'Real Estate', icon: HomeIcon },
        { name: 'Engineering', icon: AcademicCapIcon },
    ];

    return (
        <div className="container mx-auto py-12">
            {/* Title and Subtitle */}
            <div className="text-center mb-12 mx-2">
                <h2 className="text-3xl font-bold mb-4">Our Industry Expertise</h2>
                <div className="w-16 h-1 bg-orange-500 mb-3 mx-auto "></div>
                <p className="mt-4 text-gray-600">
                    We've excelled our experience in a wide range of industries to bring valuable insights and provide our clients with truly beneficial solutions.
                </p>
            </div>

            {/* Industry Expertise Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mx-12">
                {industries.map((industry, index) => (
                    <div
                        key={index}
                        className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-shadow duration-300 my-2"
                    >
                        {/* Icon */}
                        <industry.icon className="w-12 h-12 mx-auto mb-4 text-blue-900" />
                        
                        {/* Industry Name */}
                        <h3 className="text-center font-semibold text-lg">
                            {industry.name}
                        </h3>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default IndustryExpertise;

import React from "react";

const solutions = [
  "Enterprise applications",
  "ERP",
  "Financial management",
  "Supply chain management",
  "Asset management",
  "Fleet management",
  "HR software",
  "eLearning software",
  "eCommerce",
  "Mobile apps",
  "Collaboration and productivity solutions",
  "Content management",
  "CRM",
  "Data analytics",
  "Web portals",
  "ITSM",
];

export default function SolutionsSection() {
  return (
    <div className="bg-blue-900 py-16 mt-16">
      <div className="max-w-7xl mx-auto text-center px-4">
        <h2 className="text-white text-4xl font-bold mb-3 text-left">Solutions We Deliver</h2>
        <div className="w-16 h-1 bg-orange-500 mb-8"></div>
        <p className="text-blue-200 mb-10 text-left">
          We provide comprehensive IT solutions to facilitate seamless B2B and B2C interactions, as well as optimize internal <br /> business processes, ensuring efficient and effective operations.
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {solutions.map((solution, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg flex items-center justify-center transition transform hover:scale-105 hover:bg-gradient-to-r from-blue-100 to-blue-200"
            >
              <span className="text-blue-900 font-semibold">{solution}</span>
              <span className="ml-2 text-blue-900">&rarr;</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
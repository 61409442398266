import React, { useState } from 'react';

const Offerings = () => {
  const [selectedOption, setSelectedOption] = useState('Software Development');

  const offerings = [
    'Software Development',
    'Data Analytics',
    'Artificial Intelligence',
    'IT Consulting',
    'Dev-Ops Solutions',
    'Cyber Security',
    'Digitalization',
    'Application Design',
    'Digital Marketing',
    'Digital Marketing',
  ];

  const offeringContent = {
    'Software Development': (
      <div className="text-left">
        <h3 className="text-2xl font-bold mb-4 text-blue-700">Software Development</h3>
        <p className="text-base mb-4 text-gray-700 text-lg">
          With 35 years of business excellence, we develop reliable, scalable, and secure software solutions for any OS, browser, and device. Our deep industry expertise combined with the latest IT advancements delivers custom solutions and products that perfectly fit the needs and behavior of your users.
        </p>
        <ul className="list-disc ml-5 text-gray-600 text-lg">
  <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">
    Software consulting
  </li>
  <li className="  py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">
    Custom software development
  </li>
  <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">
    Software development outsourcing
  </li>
  <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">
    Software product development
  </li>
  <li className="  py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">
    Team augmentation
  </li>

</ul>

      </div>
    ),
    'Data Analytics': (
      <div className="text-left">
        <h3 className="text-2xl font-bold mb-4 text-blue-700">Data Analytics</h3>
        <p className="text-base mb-4 text-gray-700 text-lg">
          Leverage the power of data to drive business decisions. Our data analytics services include data processing, predictive analytics, and visualization to help you make informed decisions based on actionable insights.
        </p>
        <ul className="list-disc ml-5 text-gray-600 text-lg">
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Data processing and cleaning</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Predictive analytics</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Data visualization</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Business intelligence</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Big data solutions</li>
        </ul>
      </div>
    ),
    'Artificial Intelligence': (
      <div className="text-left">
        <h3 className="text-2xl font-bold mb-4 text-blue-700">Artificial Intelligence</h3>
        <p className="text-base mb-4 text-gray-700 text-lg">
          Our AI services encompass machine learning, natural language processing, and intelligent automation to create cutting-edge solutions that drive efficiency and innovation across industries.
        </p>
        <ul className="list-disc ml-5 text-gray-600 text-lg">
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Machine learning models</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Natural language processing</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Intelligent automation</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">AI consulting</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Predictive modeling</li>
        </ul>
      </div>
    ),
    'IT Consulting': (
      <div className="text-left">
        <h3 className="text-2xl font-bold mb-4 text-blue-700">IT Consulting</h3>
        <p className="text-base mb-4 text-gray-700 text-lg">
          We offer expert IT consulting to help you navigate complex technology challenges. Our consultants provide strategic advice and practical solutions tailored to your business needs.
        </p>
        <ul className="list-disc ml-5 text-gray-600 text-lg">
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">IT strategy and planning</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Technology assessments</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300"> IT project management</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">System integration</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Digital transformation</li>
        </ul>
      </div>
    ),
    'Dev-Ops Solutions': (
      <div className="text-left">
        <h3 className="text-2xl font-bold mb-4 text-blue-700">Dev-Ops Solutions</h3>
        <p className="text-base mb-4 text-gray-700 text-lg">
          Streamline your development and operations with our DevOps solutions, which enhance collaboration, automate processes, and accelerate delivery through continuous integration and continuous deployment.
        </p>
        <ul className="list-disc ml-5 text-gray-600 text-lg">
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">CI/CD pipelines</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Infrastructure as code</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Automated testing</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Monitoring and logging</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Cloud deployments</li>
        </ul>
      </div>
    ),
    'Cyber Security': (
      <div className="text-left">
        <h3 className="text-2xl font-bold mb-4 text-blue-700">Cyber Security</h3>
        <p className="text-base mb-4 text-gray-700 text-lg">
          Protect your digital assets with our comprehensive cybersecurity services. We offer solutions to safeguard against threats and ensure the integrity and confidentiality of your information.
        </p>
        <ul className="list-disc ml-5 text-gray-600 text-lg">
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Threat assessment</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Incident response</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Vulnerability management</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Compliance and audits</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Security training</li>
        </ul>
      </div>
    ),
    'Digitalization': (
      <div className="text-left">
        <h3 className="text-2xl font-bold mb-4 text-blue-700">Digitalization</h3>
        <p className=" mb-4 text-gray-700 text-lg text-lg">
          Transform your business processes through digitalization. Our solutions streamline operations, enhance customer experiences, and drive innovation by integrating digital technologies.
        </p>
        <ul className="list-disc ml-5 text-gray-600 text-lg">
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Process automation</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Digital strategy development</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Customer experience management</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Data-driven decision making</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Technology integration</li>
        </ul>
      </div>
    ),
    'Application Design': (
      <div className="text-left">
        <h3 className="text-2xl font-bold mb-4 text-blue-700">Application Design</h3>
        <p className="text-base mb-4 text-gray-700 text-lg">
          Create engaging and user-friendly applications with our design services. We focus on delivering intuitive interfaces and seamless user experiences that meet the needs of your target audience.
        </p>
        <ul className="list-disc ml-5 text-gray-600 text-lg">
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">User experience (UX) design</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">User interface (UI) design</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Prototype development</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Usability testing</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Design systems</li>
        </ul>
      </div>
    ),
    'Digital Marketing': (
      <div className="text-left">
        <h3 className="text-2xl font-bold mb-4 text-blue-700">Digital Marketing</h3>
        <p className="text-base mb-4 text-gray-700 text-lg">
          Boost your online presence with our digital marketing services. We provide strategies and campaigns to enhance your brand visibility, engage your audience, and drive conversions.
        </p>
        <ul className="list-disc ml-5 text-gray-600 text-lg">
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Search engine optimization (SEO)</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Pay-per-click (PPC) advertising</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Social media marketing</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Content marketing</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Email marketing</li>
        </ul>
      </div>
    ),
    'Digital Marketing': (
      <div className="text-left">
        <h3 className="text-2xl font-bold mb-4 text-blue-700">Digital Marketing</h3>
        <p className="text-base mb-4 text-gray-700 text-lg">
          Boost your online presence with our digital marketing services. We provide strategies and campaigns to enhance your brand visibility, engage your audience, and drive conversions.
        </p>
        <ul className="list-disc ml-5 text-gray-600 text-lg">
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Search engine optimization (SEO)</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Pay-per-click (PPC) advertising</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Social media marketing</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Content marketing</li>
          <li className=" py-1 max-w-sm hover:text-white hover:bg-blue-900 hover:rounded-lg hover:px-4 hover:py-2 transition-colors duration-300">Email marketing</li>
        </ul>
      </div>
    ),
  };

  return (
    <div className="offerings-container mx-auto px-4 sm:px-8 my-12 ">
      <div className="max-w-7xl mx-auto lg:mx-16 ">
        <h2 className="text-center text-4xl font-bold mb-6">Explore Our Offerings</h2>
        <div className="w-16 h-1 bg-orange-500 mb-8 mx-auto "></div>
        <div className="flex flex-col lg:flex-row gap-8 lg:gap-16">
          {/* Offerings Sidebar */}
          <div className="offerings-sidebar hidden lg:block lg:w-2/5 p-6 rounded-lg bg-blue-900 shadow-xl" >
            <h3 className="text-xl font-semibold text-white mb-4">Our Services</h3>
            <div className="grid grid-cols-2 gap-4">
              {offerings.map((offering, index) => (
                <div
                  key={index}
                  className={`p-4 cursor-pointer rounded-lg transition-transform duration-300 ease-in-out ${
                    selectedOption === offering
                      ? 'bg-blue-700 text-white transform scale-105'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                  onClick={() => setSelectedOption(offering)}
                >
                  {offering}
                </div>
              ))}
            </div>
          </div>

          {/* Offering Details */}
          <div className="offering-details lg:w-3/5 bg-white p-8 rounded-lg shadow-xl transition-transform duration-300 ease-in-out transform">
            {offeringContent[selectedOption]}
          </div>
        </div>

        {/* Mobile View */}
        <div className="lg:hidden mt-8">
          <div className="space-y-8">
            {offerings.map((offering, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                {offeringContent[offering]}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offerings;

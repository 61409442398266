import './App.css';
import ContactUsSection from './components/ContactUsSection';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import IndustryExpertise from './components/IndustryExpertise';
import LandingPage from './components/LandingPage';
import Offerings from './components/Offerings';
import OverviewSection from './components/OverviewSection';
import SolutionsSection from './components/SolutionsSection';
import SuccessStories from './components/SuccessStories';
import TechnologiesSection from './components/TechnologiesSection';
import TextImageSection from './components/TextImageSection';


function App() {
  return (
    <div className="App">
    <LandingPage/>
    <OverviewSection/>
    <Offerings/>
    <TextImageSection/>
    <SuccessStories/>
    <SolutionsSection/>
   
    <TechnologiesSection/>
 
   
    
    <IndustryExpertise/>
    {/* <FAQ/> */}
    <ContactUsSection/>
    <Footer/>
    </div>
  );
}

export default App;

// import React from "react";

// export default function ContactUsSection() {
//   return (
//     <div className="bg-gray-100 py-16">
//       <div className="max-w-6xl mx-auto px-4 md:flex md:space-x-12">
//         {/* Left Side: Form */}
//         <div className="md:w-1/2">
//           <h2 className="text-gray-800 text-4xl font-bold mb-8 text-left">
//             Contact Us
//           </h2>
//           <p className="text-gray-600 text-lg mb-6">
//             Have questions or need a consultation? Fill out the form below, and our team will get back to you shortly.
//           </p>
//           <div className="bg-white p-8 rounded-lg shadow-lg">
//             <form className="space-y-6">
//               <div>
//                 <label className="block text-gray-700 font-medium mb-2" htmlFor="name">
//                   Your Name
//                 </label>
//                 <input
//                   type="text"
//                   id="name"
//                   className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
//                   placeholder="Enter your name"
//                 />
//               </div>
//               <div>
//                 <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
//                   Email Address
//                 </label>
//                 <input
//                   type="email"
//                   id="email"
//                   className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
//                   placeholder="Enter your email"
//                 />
//               </div>
//               <div>
//                 <label className="block text-gray-700 font-medium mb-2" htmlFor="message">
//                   Your Message
//                 </label>
//                 <textarea
//                   id="message"
//                   rows="6"
//                   className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
//                   placeholder="Enter your message"
//                 ></textarea>
//               </div>
//               <div>
//                 <label className="block text-gray-700 font-medium mb-2" htmlFor="consultation">
//                   Need a Consultation?
//                 </label>
//                 <textarea
//                   id="consultation"
//                   rows="4"
//                   className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
//                   placeholder="Describe your consultation needs"
//                 ></textarea>
//               </div>
//               <div>
//                 <button
//                   type="submit"
//                   className="w-full bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
//                 >
//                   Submit
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>

//         {/* Right Side: Image */}
//         <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center items-center">
//           <img
//             src="https://via.placeholder.com/500x500" // Replace with actual image URL
//             alt="Contact Us Illustration"
//             className="w-full h-auto rounded-lg shadow-lg"
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

import React from "react";
import { motion } from "framer-motion";
import contact from '../assets/contact2.png';
export default function ContactUsSection() {
  return (<>
<div>

</div>

    <div className=" py-16">
    <h2 className="text-gray-800 text-4xl font-bold mb-8 text-center">
            Get in Touch
          </h2>
          <div className="w-16 h-1 bg-orange-500 mb-4 mx-auto "></div>
          <p className="text-gray-700  mb-8 text-center mx-2">
            Have any questions or need a consultation? Fill out the form below,
            and our team will get back to you as soon as possible.
          </p>
      <div className="max-w-7xl mx-auto px-4 md:flex md:space-x-16 items-center">
        {/* Left Side: Form */}
        <motion.div
          className="md:w-1/2"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          
          <div className=" bg-gradient-to-r from-blue-100 to-blue-200 p-10 rounded-xl shadow-2xl">
            <form className="space-y-8">
              <div>
                <label
                  className="block text-gray-800 font-medium mb-3 text-left"
                  htmlFor="name"
                >
                  Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full p-4 border border-gray-300 rounded-lg focus:ring focus:ring-blue-300"
                  placeholder="Enter your name"
                />
              </div>
              <div>
                <label
                  className="block text-gray-800 font-medium mb-3 text-left"
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-4 border border-gray-300 rounded-lg focus:ring focus:ring-blue-300"
                  placeholder="Enter your email"
                />
              </div>
              <div>
                <label
                  className="block text-gray-800 font-medium mb-3 text-left"
                  htmlFor="message"
                >
                  Your Message
                </label>
                <textarea
                  id="message"
                  rows="5"
                  className="w-full p-4 border border-gray-300 rounded-lg focus:ring focus:ring-blue-300"
                  placeholder="Enter your message"
                ></textarea>
              </div>
              <div>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="submit"
                  className="w-full bg-blue-600 text-white py-4 rounded-lg font-semibold shadow-lg hover:bg-blue-700 transition-transform"
                >
                  Submit
                </motion.button>
              </div>
            </form>
          </div>
        </motion.div>

        {/* Right Side: Image */}
        <motion.div
          className="md:w-1/2 mt-12 md:mt-0 flex justify-center items-center"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <img
            src={contact} // Replace with actual image URL
            alt="Contact Us Illustration"
            className="w-full h-auto rounded-lg  bg-white"
          />
        </motion.div>
      </div>
    </div>
    </>
  );
}

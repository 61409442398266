import React, { useState, useEffect } from 'react';

const Typewriter = ({ words }) => {
  const [index, setIndex] = useState(0);
  const [displayedWord, setDisplayedWord] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2500); // Change word every 1 second

    return () => clearInterval(interval);
  }, [words]);

  useEffect(() => {
    const word = words[index];
    let timeout;
    let charIndex = 0;

    const typeWord = () => {
      if (charIndex < word.length) {
        setDisplayedWord((prev) => prev + word[charIndex]);
        charIndex++;
        timeout = setTimeout(typeWord, 200);
      }
    };

    setDisplayedWord('');
    typeWord();

    return () => clearTimeout(timeout);
  }, [index, words]);

  return (
    <div className="inline-block border border-gray-400 px-3 py-1 ">
      <span className='text-yellow-500'>{displayedWord}</span>
    </div>
  );
};

export default Typewriter;

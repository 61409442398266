
import React from 'react';
import CountUp from 'react-countup';
import { useState, useEffect } from 'react';

const clientLogos = [
  "https://www.scnsoft.com/---home-page-illustrations/clients/deloitte.svg",
  "https://www.scnsoft.com/---home-page-illustrations/clients/deloitte.svg",
  "https://www.scnsoft.com/---home-page-illustrations/clients/deloitte.svg",
  "https://www.scnsoft.com/---home-page-illustrations/clients/deloitte.svg",
  "https://www.scnsoft.com/---home-page-illustrations/clients/deloitte.svg",
  "https://www.scnsoft.com/---home-page-illustrations/clients/deloitte.svg",
  "https://www.scnsoft.com/---home-page-illustrations/clients/deloitte.svg",
  "https://www.scnsoft.com/---home-page-illustrations/clients/deloitte.svg",
  "https://www.scnsoft.com/---home-page-illustrations/clients/deloitte.svg",
  "https://www.scnsoft.com/---home-page-illustrations/clients/deloitte.svg",
  
];

export default function OverviewSection() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setOffset(prev => (prev + 1) % clientLogos.length);
    }, 2000); // Adjust timing to your preference
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-gray-100 py-16 mx-auto px-5 sm:px-8 ">
      <div className="container mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-4 text-center gap-8 mb-16">
          <div className="p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
            <p className="text-5xl font-bold text-blue-700">
              <CountUp end={35} duration={4} /> {/* 35 years of expertise */}
            </p>
            <p className="text-gray-700">years of expertise</p>
          </div>
          <div className="p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
            <p className="text-5xl font-bold text-blue-700">
              <CountUp end={750} duration={4} suffix="+" /> {/* 750+ IT professionals */}
            </p>
            <p className="text-gray-700">IT professionals</p>
          </div>
          <div className="p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
            <p className="text-5xl font-bold text-blue-700">
              <CountUp end={4000} duration={4} /> {/* 4K success stories */}
            </p>
            <p className="text-gray-700">success stories</p>
          </div>
          <div className="p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
            <p className="text-5xl font-bold text-blue-700">
              <CountUp end={30} duration={4} suffix="+" /> {/* 30+ industries covered */}
            </p>
            <p className="text-gray-700">industries covered</p>
          </div>
        </div>
        <p className="text-center text-gray-600 max-w-6xl mx-auto mb-16 text-lg">
          Since 1989. For millions of users. We are responsible for delivering top-quality, secure, and adaptable digital solutions that satisfy the needs of today and unlock the opportunities of tomorrow.
        </p>
        <div className="overflow-hidden relative">
          <div className="flex justify-center space-x-12 animate-marquee overflow-hidden relative">
            {clientLogos.map((logo, index) => (
              <img
                key={index}
                src={logo}
                alt={`Client ${index + 1}`}
                className="h-9 transform transition-transform duration-500 ease-in-out animate-slide"
                style={{ transform: `translateX(-${offset * 100}%)` }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}


import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Typewriter from './Typewriter';
import home1 from '../assets/home1.jpg'
import home4 from '../assets/home4.jpg'


const LandingPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const words = ['encourage', 'ensure', 'guarantee', 'accelerate'];

  const slides = [
    {
      image: "https://www.scnsoft.com/---home-page-illustrations/photos-for-banner/image-thumb__37849__slider-with-banners/home-page---5---2560~-~media--521a83d4--query.webp",
      title: "Designing Solutions to Perfection!",
    },
    {
      image: home1 ,
      title: "Innovative Solutions",
    },
    {
      image: home4,
      title: "Software Consulting and Development",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000); // Slide transition interval

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className='' >
      <Navbar />
      <div className="relative h-screen bg-black bg-opacity-40">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${
              index === currentSlide ? 'opacity-100' : 'opacity-0'
            }`}
            style={{ backgroundImage: `url(${slide.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <div className="container mx-auto px-4 h-full flex flex-col justify-center items-center text-white  ">
              <h1 className="text-4xl md:text-6xl font-serif text-center">{slide.title}</h1>
              <p className="mt-4 text-lg md:text-2xl text-center">
                We <Typewriter words={words} /> project success
              </p>
            </div>
          </div>
        ))}

        {/* Navigation Dots */}
        <div className="absolute inset-x-0 bottom-0 flex justify-center mb-4">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`h-3 w-3 mx-2 rounded-full transition-colors duration-300 ${
                index === currentSlide ? 'bg-white' : 'bg-gray-400'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

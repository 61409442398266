
import React from "react";
import { FaPython, FaJava, FaNodeJs, FaPhp, FaReact, FaVuejs, FaAngular, FaAws, FaGoogle, FaDocker } from "react-icons/fa";
import { SiDotnet, SiGo, SiAzuredevops, SiMysql, SiMongodb, SiPostgresql, SiOracle, SiRedis, SiTensorflow, SiKubernetes } from "react-icons/si";

const technologies = [
  {
    category: "Web",
    subcategories: {
      "Back End": [
        { name: "NET", icon: <SiDotnet /> },
        { name: "Java", icon: <FaJava /> },
        { name: "Python", icon: <FaPython /> },
        { name: "Node.js", icon: <FaNodeJs /> },
        { name: "Go", icon: <SiGo /> },
        { name: "PHP", icon: <FaPhp /> },
      ],
      "Front End": [
        { name: "React", icon: <FaReact /> },
        { name: "Vue", icon: <FaVuejs /> },
        { name: "Angular", icon: <FaAngular /> },
      ],
    },
  },
  {
    category: "Clouds",
    subcategories: {
      "Cloud Services": [
        { name: "AWS", icon: <FaAws /> },
        { name: "Google Cloud", icon: <FaGoogle /> },
        { name: "Azure", icon: <SiAzuredevops /> },
      ],
    },
  },
  {
    category: "DevOps",
    subcategories: {
      "Containers": [
        { name: "Docker", icon: <FaDocker /> },
        { name: "Kubernetes", icon: <SiKubernetes /> },
      ],
    },
  },
  {
    category: "Databases",
    subcategories: {
      "SQL": [
        { name: "MySQL", icon: <SiMysql /> },
        { name: "PostgreSQL", icon: <SiPostgresql /> },
        { name: "Oracle", icon: <SiOracle /> },
      ],
      "NoSQL": [
        { name: "MongoDB", icon: <SiMongodb /> },
        { name: "Redis", icon: <SiRedis /> },
      ],
    },
  },
  {
    category: "Machine Learning",
    subcategories: {
      "Frameworks": [
        { name: "TensorFlow", icon: <SiTensorflow /> },
      ],
    },
  },
];

export default function TechnologiesSection() {
  return (


    <div className=" py-16">
 <h2 className="text-gray-800 text-3xl font-bold mb-6 mx-8 text-center">
          Technologies and Platforms We Work With
        </h2>
        <div className="w-16 h-1 bg-orange-500 mb-8 mx-auto "></div>
      <div className="max-w-7xl  mx-auto px-4 bg-gradient-to-br from-blue-200 to-blue-300 shadow-2xl">
       
        <div className="space-y-12 p-5">
          {technologies.map((tech, index) => (
            <div key={index}>
              <h3 className="text-gray-700 text-2xl font-semibold mb-4 text-left">
                {tech.category}
              </h3>
              {Object.entries(tech.subcategories).map(([subcategory, items], idx) => (
                <div key={idx} className="mb-8">
                  <h4 className="text-gray-600 text-xl font-medium mb-2 text-left">
                    {subcategory}
                  </h4>
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-6">
                    {items.map((item, subIdx) => (
                      <div
                        key={subIdx}
                        className="bg-white p-4 rounded-lg shadow-md hover:shadow-xl transform transition hover:-translate-y-1 flex items-center gap-3"
                      >
                        <div className="text-3xl text-blue-700">
                          {item.icon}
                        </div>
                        <span className="text-gray-700 font-semibold">
                          {item.name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
